<template>
  <!-- 配置模板 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>充值页</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="content">
    <div class="tool-view">
      <!-- 操作 -->
      <div style="flex: 1;"></div>
      <!-- 其他操作 -->
      <a-button type="primary" @click="add"><plus-outlined />创建充值页</a-button>
    </div>
    <div class="table-box">
      <a-table
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <!-- 操作 -->
          <template v-if="column.key === 'operation'">
            <a class="operation-item" @click="touchEdit(record)">编辑</a>
            <a class="operation-item" @click="touchCopy(record)">复制链接</a>
          </template>
        </template>
      </a-table>
    </div>
    <!-- 新增、编辑弹窗 -->
    <Add ref="RefAdd" @success="getData" />
    <!-- 新增、编辑弹窗 -->
    <Copy ref="RefCopy" />
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, onBeforeMount } from 'vue'
import { message, Modal } from 'ant-design-vue'
import Add from './components-charge-page/Add'
import Copy from './components-charge-page/Copy'
import { chargePageList } from '@/api/copyright'

// 新增
let RefAdd = ref(null)
// 拷贝
let RefCopy = ref(null)
// 数据源
let dataSource = ref(null)
// 活动类型
let activitiesType = ref(undefined)
// 活动状态
let activitiesStatus = ref(undefined)
// 加载
let isLoading = ref(false)
// 表头
let columns = reactive([
  {
    title: '页面ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '页面说明',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '最新编辑人',
    dataIndex: 'edit_username',
    key: 'edit_username'
  },
  {
    title: '上次编辑时间',
    dataIndex: 'updated_at',
    key: 'updated_at'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 分页
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 表格change
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 请求分页数据
function getData () {
  isLoading.value = true
  const params = {
    type: activitiesType.value,
    status: activitiesStatus.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  chargePageList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新增弹窗
function add () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}

// 拷贝
function touchCopy (record) {
  RefCopy.value.showDrawer(record)
}
</script>

<style lang="less" scoped>
.content {
  padding: 24px 0;
  .tool-view {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tool-input {
      width: 150px;
      margin-right: 16px;
    }
  }
  .operation-item {
    margin-right: 8px;
  }
}

</style>