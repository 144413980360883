<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑充值页' :'创建充值页'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 活动标题 -->
      <a-form-item
        label="活动标题"
        name="name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- banner -->
      <a-form-item
        label="banner"
        name="banner_urls"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="670"
          :imgSizeHeight="332"
          imgSizeError="图片尺寸为 670 * 332"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'banner_urls') }"
          :fileList="formState.banner_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 670x332</div>
        <div class="form-upload-list" v-if="formState.banner_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.banner_urls"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('banner_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>
      <!-- 默认付费模板 -->
      <a-form-item
        label="付费模板"
        name="charge_id"
        :rules="[{ required: true }]"
      >
        <a-select
          class="form-input"
          show-search
          style="width: 80%;"
          v-model:value="formState.charge_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filter-option="filterOption"
          @mouseleave="mouseleave"
        >
          <template
            v-for="item in defaultList"
          >
            <a-select-option
              v-if="item.detail && item.detail.length"
              :key="`${item.id}-${item.name}`"
              :value="item.id"
              @mouseenter="mouseenter(item.id)"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
        <a class="recharge-delete" @click="touchPreview(formState.charge_id)">预览</a>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 支付模版预览 -->
    <PayPreviewModal ref="RefPayPreviewModal"></PayPreviewModal>
    <!-- 预览模版 -->
    <PayPreviewView ref="RefPayPreviewView" :right="840"></PayPreviewView>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { chargePageSave, chargeAll} from '@/api/copyright'
import Pub from '@/utils/public'
import PayPreviewModal from '@/views/operate/config/components-coin-custom/PreviewModal'
import PayPreviewView from '@/views/operate/config/components-coin-custom/PreviewView'
import dayjs from 'dayjs'


// 支付模版预览
const RefPayPreviewModal = ref(null)
const RefPayPreviewView = ref(null)
// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref({})
// 默认充值模版列表
let defaultList = ref([])
// 表单
let formState = reactive({

  // 活动标题
  name: undefined,
  // 价格
  money: undefined,
  // banner图
  banner_urls: [],
  banner_url: undefined,
  // 模板id
  charge_id: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    // 获取选择项
    getSelectList()
    if (params) {
      // id
      id.value = params.id
      formState.charge_id = params.charge_id
      formState.name = params.name
      formState.banner_urls = [{
        url: params.banner_url
      }]
      formState.banner_url = params.banner_url
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    formState.banner_url = formState.banner_urls[0].url
    const params = {
      id: id.value,
      banner_url: formState.banner_urls[0].url,
      name: formState.name,
      charge_id: formState.charge_id,
    }
    chargePageSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        if (id.value) {
          message.success('更新成功')
        } else {
          message.success('创建成功')
        }
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 校验虚拟币数量
function validator_coin (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}
// 校验价格
function validator_money (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}

// 进入元素
function mouseenter (id) {
  RefPayPreviewView.value.show(id)
}

// 离开元素
function mouseleave () {
  RefPayPreviewView.value.hide()
}

// 预览充值模版
function touchPreview(charge_id) {
  RefPayPreviewModal.value.showModal(charge_id)
}

// 获取选择项
function getSelectList () {
  // 默认模版
  chargeAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      defaultList.value = data
    }
  })
}


// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 70%;
}
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 124px;
}
.desc-textarea {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}
.sync-tt {
  padding-top: 6px;
}
.recharge-delete {
  margin-left: 12px;
  white-space: nowrap;
}
</style>